<template>
  <v-container id="dashboard" fluid tag="section">
    <v-overlay v-if="progress">
      <v-progress-circular indeterminate size="64" />
    </v-overlay>

    <v-row align="center" justify="end">
      <!-- 凡例 -->
      <v-col>
        <v-row class="pl-8">
          <v-col cols="auto">
            <div class="sales-guide-box sales-guide-before" />
          </v-col>
          <v-col cols="auto" class="pl-0">
            <span>先払い</span>
          </v-col>
          <v-col cols="auto">
            <div class="sales-guide-box sales-guide-after" />
          </v-col>
          <v-col cols="auto" class="pl-0">
            <span>後払い</span>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="auto" align="center">
        <v-btn-toggle v-model="toggleChartType">
          <v-btn :value="0" text>日</v-btn>
          <v-btn :value="1" text>週</v-btn>
          <v-btn :value="2" text>月</v-btn>
        </v-btn-toggle>
      </v-col>
      <v-col cols="2" align="center">
        <v-select
          v-model="tmpChartItemsSelectedId"
          :items="tmpChartItems"
          item-value="id"
          item-text="text"
          required
          :disabled="tmpChartItems.length === 0"
          return-object
          @change="changeChartPulldownSelect"
        />
      </v-col>
    </v-row>

    <!-- グラフ -->
    <v-row>
      <v-col cols="12">
        <chartist
          :data="tmpChart.data"
          :options="getCommonOptions()"
          :responsive-options="getCommonResponsiveOptions()"
          type="Bar"
          class="sales-graph"
          style="max-height: 450px"
        />
      </v-col>
    </v-row>

    <!-- サマリ -->
    <v-row align="center" class="mt-8">
      <v-col cols="4" align="center">
        <v-row>
          <v-col align="center" class="pa-0">
            <span class="text-h4">今日</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col align="center">
            <span class="text-h2">￥</span>
            <span class="text-h2">{{ tmpTotal.today | addComma }}</span>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="4" align="center">
        <v-row>
          <v-col align="center" class="pa-0">
            <span class="text-h4">今週</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col align="center">
            <span class="text-h2">￥</span>
            <span class="text-h2">{{ tmpTotal.week | addComma }}</span>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="4" align="center">
        <v-row>
          <v-col align="center" class="pa-0">
            <span class="text-h4">今月</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col align="center">
            <span class="text-h2">￥</span>
            <span class="text-h2">{{ tmpTotal.month | addComma }}</span>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

// tooltip
import "chartist/dist/chartist.min.css";
import "chartist-plugin-tooltips-updated";
import "chartist-plugin-tooltips-updated/dist/chartist-plugin-tooltip.css";

import { CHART_TYPE } from "../../constants";
import moment from "moment";

export default {
  data() {
    return {
      // グラフ切替
      toggleChartType: CHART_TYPE.DATE,

      // プルダウン
      tmpChartItems: [],
      tmpChartItemsSelectedId: 0,

      // グラフデータ (表示用)
      tmpChart: {
        // chartist
        data: {},
        options: {},
        responsiveOptions: [],
      },
      tmpTotal: {
        today: 0,
        week: 0,
        month: 0,
      },
    };
  },
  computed: {
    ...mapGetters(["progress", "salesChart", "salesTotal"]),
  },
  watch: {
    toggleChartType: {
      immediate: true, // 初回も発火
      handler: function (newValue) {
        // console.log("[watch]toggleChartType", newValue);
        this.changeChartType(newValue);
      },
    },
    salesChart: {
      handler: function (newValue) {
        if (!newValue || !Object.keys(newValue).length) {
          return;
        }
        this.applyChartData(newValue);
      },
    },
    salesTotal: {
      handler: function (newValue) {
        if (!newValue || !Object.keys(newValue).length) {
          return;
        }
        this.applyTotal(newValue);
      },
    },
  },
  mounted() {},
  methods: {
    ...mapActions(["fetchSales"]),
    getCommonOptions() {
      return {
        plugins: [this.$chartist.plugins.tooltip()],
        axisX: {
          showGrid: true,
          labelInterpolationFnc: function (value) {
            return value;
          },
        },
        axisY: {
          offset: 80, // margin-left (px)
          scaleMinSpace: 30, // 指定高さ(px) で数値は auto scale
          labelInterpolationFnc: function (value) {
            return value;
            // return value + " 円 ";
            // return value / 10000 + " 万";
          },
        },
        stackBars: true,
        low: 0,
        // high: 1000,
        chartPadding: {
          top: 0,
          right: 5,
          bottom: 0,
          left: 0,
        },
      };
    },
    getCommonResponsiveOptions() {
      return [];
    },
    applyChartData(newData) {
      // グラフ部
      this.tmpChart = {
        // chartist
        data: newData.data,
        options: this.getCommonOptions(),
        responsiveOptions: this.getCommonResponsiveOptions(),
        //
        type: newData.type,
      };

      // プルダウン
      const chartType = newData.type;
      const oldestYMD = newData.oldestYMD;
      this.applyChartSelectItems(chartType, oldestYMD);
    },
    applyChartSelectItems(chartType, oldestYMDText) {
      console.log("#applyChartSelectItems", chartType, oldestYMDText);

      // 「日」選択中
      let items = [];
      if (chartType === CHART_TYPE.DATE) {
        items = this.createChartSelectItemsForDate(oldestYMDText);
      } else if (chartType === CHART_TYPE.WEEK) {
        // 「週」選択中
        items = [];
      } else if (chartType === CHART_TYPE.MONTH) {
        items = this.createChartSelectItemsForMonth(oldestYMDText);
      }

      // プルダウン差し替え
      this.tmpChartItems = items;
    },
    createChartSelectItemsForDate(oldestYMDText) {
      let items = [];
      if (oldestYMDText) {
        const oldestDate = moment(oldestYMDText, "YYYY/M/D"); // 最も古い注文日付 (YYYY/M/D)
        // console.log("oldestDate", oldestDate);
        const end = moment().endOf("month");
        // console.log("end", end);
        for (
          let target = oldestDate.clone();
          target.isSameOrBefore(end);
          target.add(1, "months") // 1ヶ月進める
        ) {
          const tmpY = target.format("YYYY");
          const tmpM = target.format("M");
          items.unshift({ yyyy: tmpY, mm: tmpM, text: `${tmpY}/${tmpM}` });
        }
      }
      items.unshift({ text: "過去14日間" });
      items = items.map((item, index) => {
        item.id = index;
        return item;
      });
      return items;
    },
    createChartSelectItemsForMonth(oldestYMDText) {
      let items = [];
      if (oldestYMDText) {
        const oldestDate = moment(oldestYMDText, "YYYY/M/D"); // 最も古い注文日付 (YYYY/M/D)
        // console.log("oldestDate", oldestDate);
        const end = moment().endOf("year");
        // console.log("end", end);
        for (
          let target = oldestDate.clone();
          target.isSameOrBefore(end);
          target.add(1, "years") // 1年進める
        ) {
          const tmpY = target.format("YYYY");
          items.unshift({ yyyy: tmpY, text: `${tmpY}年` });
        }
      }
      items.unshift({ text: "過去12ヶ月" });
      items = items.map((item, index) => {
        item.id = index;
        return item;
      });
      return items;
    },
    applyTotal(newData) {
      // サマリ部
      this.tmpTotal = newData;
    },
    changeChartType(chartType) {
      console.log("changeChartType", chartType, this.salesChart);

      // グラフデータ取得
      if (chartType === CHART_TYPE.DATE) {
        this.fetchSales({ byDay: true });
      } else if (chartType === CHART_TYPE.WEEK) {
        this.fetchSales({ byWeek: true });
      } else if (chartType === CHART_TYPE.MONTH) {
        this.fetchSales({ byMonth: true });
      }

      // プルダウン初期化 (グラフ種別切替時)
      this.tmpChartItems = [];
      this.tmpChartItemsSelectedId = 0; // 先頭
    },
    changeChartPulldownSelect(selected) {
      // 同一グラフ種別(日/週/月) プルダウン切替時
      console.log("changeChartPulldownSelect", this.toggleChartType, selected);
      if (this.toggleChartType === CHART_TYPE.DATE) {
        if (selected.id === 0) {
          // 先頭
          this.fetchSales({ byDay: true });
        } else {
          this.fetchSales({
            byDay: true,
            params: { yyyy: selected.yyyy, mm: selected.mm },
          });
        }
      } else if (this.toggleChartType === CHART_TYPE.MONTH) {
        if (selected.id === 0) {
          // 先頭
          this.fetchSales({ byMonth: true });
        } else {
          this.fetchSales({ byMonth: true, params: { yyyy: selected.yyyy } });
        }
      }
    },
  },
};
</script>

<style>
:root {
  --brown: #8d6e63;
  --orange: #ffa726;
}

/* グラフ */
.sales-graph .ct-bar {
  stroke-width: 20px;
}
.sales-graph .ct-series-a .ct-bar {
  stroke: var(--brown);
}
.sales-graph .ct-series-b .ct-bar {
  stroke: var(--orange);
}
.sales-graph .ct-label {
  font-size: 12px;
  /* color: red; */
}

/* 凡例 */
.sales-guide-box {
  width: 40px;
  height: 20px;
}
.sales-guide-before {
  background-color: var(--brown);
}
.sales-guide-after {
  background-color: var(--orange);
}
</style>
